import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './App.css'; // Import the CSS file

function Game() {
  const { id } = useParams();
  const [sheet, setSheet] = useState(null);
  const [error, setError] = useState('');
  const [grayscaleImages, setGrayscaleImages] = useState(new Set());
  const [isWinner, setIsWinner] = useState(false);

  useEffect(() => {
    const fetchSheet = async () => {
      try {
        const response = await axios.post('https://backend-cncf-loteria.ol.itmlabs.io/get-sheet', {
          sheet_number: parseInt(id, 10)  // Ensure id is an integer
        });
        setSheet(response.data);
        setError('');
      } catch (error) {
        setError(error.response ? error.response.data.error : 'Error fetching sheet');
        setSheet(null);
      }
    };

    fetchSheet();
  }, [id]);

  const handleDoubleClick = (index) => {
    setGrayscaleImages((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      // Check if all images are grayscale
      if (newSet.size === 16) {
        setIsWinner(true);
      }
      return newSet;
    });
  };

  const handleWinnerClick = () => {
    setIsWinner(false);
  };

  return (
    <div className="App">
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {isWinner && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20vw',
            fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
            zIndex: 1000,
            textAlign: 'center',
          }}
          onClick={handleWinnerClick}
        >
        Lotería
        </div>
      )}
      {sheet && !isWinner && (
        <div>
          <h3>Sheet {sheet.sheet_number}</h3>
          <table className="image-table">
            <tbody>
              {Array.from({ length: 4 }, (_, rowIndex) => (
                <tr key={rowIndex}>
                  {sheet.images.slice(rowIndex * 4, rowIndex * 4 + 4).map((image, colIndex) => {
                    const index = rowIndex * 4 + colIndex;
                    const isGrayscale = grayscaleImages.has(index);
                    return (
                      <td
                        key={colIndex}
                        style={{
                          backgroundColor: isGrayscale ? 'black' : 'transparent',
                        }}
                        onDoubleClick={() => handleDoubleClick(index)}
                      >
                        <img
                          src={image}
                          alt={`Sheet ${sheet.sheet_number} - Image ${index + 1}`}
                          style={{
                            filter: isGrayscale ? 'grayscale(100%) brightness(1.1) contrast(0.1)' : 'none',
                          
 
                          }}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Game;

