import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

function Register() {
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend-cncf-loteria.ol.itmlabs.io/register', {
        email,
        fullname,
      });
      const sheetNumber = response.data.sheet_number;
      setMessage(`Registered successfully! Your sheet number is ${sheetNumber}`);
      setVariant('success');
      setEmail('');
      setFullname('');
      navigate(`/game/${sheetNumber}`);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Registration failed');
      setVariant('danger');
      if (error.response?.data?.sheet_number) {
        navigate(`/game/${error.response.data.sheet_number}`);
      }
    }
  };

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col md={2}>
          <img src="hbd_k8s-1.svg" alt="Left SVG" className="img-fluid" />
        </Col>
        <Col md={8}>
          <h2 className="text-center">Register</h2>
        </Col>
        <Col md={2}>
          <img src="logo-itm.png" alt="Right SVG" className="img-fluid" />
        </Col>
      </Row>
      {message && <Alert variant={variant}>{message}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formFullname" className="mb-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Register
        </Button>
      </Form>
    </Container>
  );
}

export default Register;
